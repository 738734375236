<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Chỉnh sửa tài khoản</h6>
        </template>
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Họ tên</label>
                    <input
                      type="text"
                      v-model="account.name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="id_card_no">Số CMT/Thẻ căn cước/Hộ chiếu</label>
                    <input
                      type="text"
                      name="id_card_no"
                      id="id_card_no"
                      disabled=""
                      :value="account.profile ? account.profile.id_card_no : ''"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      v-model="account.email"
                      class="form-control"
                      maxlength="64"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="phone">Số điện thoại</label>
                    <input
                      type="text"
                      disabled=""
                      :value="account.phone"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="birthday">Ngày sinh</label>
                    <div class="input-group">
                      <input
                        type="date"
                        v-model="account.birthday"
                        class="form-control datatable-input"
                        name="start"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="gender">Giới tính</label>
                    <select
                      name="gender"
                      id="gender"
                      class="form-control"
                      v-model="account.gender"
                    >
                      <option value="">Vui lòng chọn giới tính</option>
                      <option value="0" :selected="account.gender === 0">
                        N/A
                      </option>
                      <option value="1" :selected="account.gender === 1">
                        Nam
                      </option>
                      <option value="2" :selected="account.gender === 2">
                        Nữ
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Địa chỉ</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  v-model="account.address"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="type">Loại</label>
                <input
                  type="text"
                  disabled=""
                  :value="
                    parseInt(account.type) === 2 ? 'Doanh nghiệp' : 'Cá nhân'
                  "
                  id="type"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <span>
                  <label class="m-checkbox m-checkbox--success">
                    <input
                      onclick="return false;"
                      type="checkbox"
                      :checked="account.is_active === 1"
                    />
                    Đã kích hoạt
                    <span></span>
                  </label>
                </span>
              </div>

              <div class="form-group">
                <span>
                  <label class="m-checkbox m-checkbox--success">
                    <input
                      onclick="return false;"
                      id="status"
                      name="status"
                      type="checkbox"
                      :checked="account.status === 2"
                    />
                    Đã xác thực
                    <span></span>
                  </label>
                </span>
              </div>

              <div class="form-group">
                <span>
                  <label class="m-checkbox m-checkbox--success">
                    <input
                      onclick="return false;"
                      id="is_block"
                      name="is_block"
                      type="checkbox"
                      :checked="account.is_block === 1"
                    />
                    Khóa tài khoản
                    <span></span>
                  </label>
                </span>
              </div>

              <div class="form-group">
                <span>
                  <label class="m-checkbox m-checkbox--success">
                    <input
                      onclick="return false;"
                      type="checkbox"
                      :checked="
                        account.profile && account.profile.email_verified
                      "
                    />
                    Xác thực email
                    <span></span>
                  </label>
                </span>
              </div>

              <div class="form-group">
                <span>
                  <label class="m-checkbox m-checkbox--success">
                    <input
                      onclick="return false;"
                      type="checkbox"
                      :checked="
                        account.profile && account.profile.phone_verified
                      "
                    />
                    Xác thực SĐT
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="action text-center">
            <button
              type="submit"
              class="btn btn-primary btn-loader"
              @click="update()"
            >
              Lưu
            </button>
            <a href="/#/tools/users/account" class="btn btn-default">Hủy</a>
          </div>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  data() {
    return {
      account: {},
      genders: [
        { key: 0, value: "N/A" },
        { key: 1, value: "Nam" },
        { key: 2, value: "Nữ" },
      ],
      statusAccount: [
        { key: null, value: "Chọn trạng thái" },
        { key: -3, value: "Xác thực thất bại" },
        { key: -2, value: "Đang chờ" },
        { key: 1, value: "Kích hoạt" },
        { key: 2, value: "Đã xác thực" },
        { key: -1, value: "Đã xác thực 1 bước" },
      ],
    };
  },
  methods: {
    getDetail() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.getDetail(this.$route.params.id)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.account = response.data.data;

          this.account.birthday = this.account.profile
            ? this.account.profile.birthday
            : "";
          this.account.address = this.account.profile
            ? this.account.profile.address
            : "";
          this.account.gender = this.account.profile
            ? this.account.profile.gender
            : "";

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    update() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.update(this.$route.params.id, {
        name: this.account.name,
        email: this.account.email,
        birthday: this.account.birthday,
        gender: this.account.gender + "",
        address: this.account.address,
      })
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          if (typeof response.data.data === "object") {
            this.notifyAlert(
              "success",
              "Cập nhật thông tin người dùng thành công"
            );
            this.getDetail();
          } else {
            this.notifyAlert(
              "warn",
              "Có lỗi xảy ra khi cập nhật thông tin người dùng"
            );
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.getDetail();
  },
};
</script>
